const languagesList = {
  language: [
    { id: 1, label: 'Afrikaans - Afrikaans', value: 'af' },
    { id: 2, label: 'العربية - Arabic', value: 'ar' },
    { id: 3, label: 'Հայերեն - Armenian', value: 'hy' },
    { id: 4, label: 'Azərbaycanca - Azerbaijani', value: 'az' },
    { id: 5, label: 'Беларуская - Belarusian', value: 'be' },
    { id: 6, label: 'Bosanski - Bosnian', value: 'bs' },
    { id: 7, label: 'Български - Bulgarian', value: 'bg' },
    { id: 8, label: 'Català - Catalan', value: 'ca' },
    { id: 9, label: '中國人 - Chinese', value: 'zh' },
    { id: 10, label: 'Hrvatski - Croatian', value: 'hr' },
    { id: 11, label: 'Čeština - Czech', value: 'cs' },
    { id: 12, label: 'Dansk - Danish', value: 'da' },
    { id: 13, label: 'Nederlands - Dutch', value: 'nl' },
    { id: 14, label: 'English - English', value: 'en' },
    { id: 15, label: 'Eesti - Estonian', value: 'et' },
    { id: 16, label: 'Suomi - Finnish', value: 'fi' },
    { id: 17, label: 'Français - French', value: 'fr' },
    { id: 18, label: 'Galego - Galician', value: 'gl' },
    { id: 19, label: 'Deutsch - German', value: 'de' },
    { id: 20, label: 'Ελληνικά - Greek', value: 'el' },
    { id: 21, label: 'עברית - Hebrew', value: 'he' },
    { id: 22, label: 'हिन्दी - Hindi', value: 'hi' },
    { id: 23, label: 'Magyar - Hungarian', value: 'hu' },
    { id: 24, label: 'Íslenska - Icelandic', value: 'is' },
    { id: 25, label: 'Italiano - Italian', value: 'it' },
    { id: 26, label: '日本語 - Japanese', value: 'ja' },
    { id: 27, label: 'ಕನ್ನಡ - Kannada', value: 'kn' },
    { id: 28, label: 'Қазақ - Kazakh', value: 'kk' },
    { id: 29, label: '한국어 - Korean', value: 'ko' },
    { id: 30, label: 'Македонски - Macedonian', value: 'mk' },
    { id: 31, label: 'Bahasa Melayu - Malay', value: 'ms' },
    { id: 32, label: 'Māori - Maori', value: 'mi' },
    { id: 33, label: 'मराठी - Marathi', value: 'mr' },
    { id: 34, label: 'ਪੰਜਾਬੀ - Panjabi', value: 'pa' },
    { id: 35, label: 'فارسی - Persian', value: 'fa' },
    { id: 36, label: 'Polski - Polish', value: 'pl' },
    { id: 37, label: 'Português - Portuguese', value: 'pt' },
    { id: 38, label: 'Română - Romanian', value: 'ro' },
    { id: 39, label: 'Русский - Russian', value: 'ru' },
    { id: 40, label: 'Српски - Serbian', value: 'sr' },
    { id: 41, label: 'Slovenčina - Slovak', value: 'sk' },
    { id: 42, label: 'Slovenščina - Slovene', value: 'sl' },
    { id: 43, label: 'Español - Spanish', value: 'es' },
    { id: 44, label: 'Kiswahili - Swahili', value: 'sw' },
    { id: 45, label: 'Svenska - Swedish', value: 'sv' },
    { id: 46, label: 'தமிழ் - Tamil', value: 'ta' },
    { id: 47, label: 'ไทย - Thai', value: 'th' },
    { id: 48, label: 'Türkçe - Turkish', value: 'tr' },
    { id: 49, label: 'Українська - Ukrainian', value: 'uk' },
    { id: 50, label: 'اردو - Urdu', value: 'ur' },
    { id: 51, label: 'Tiếng Việt - Vietnamese', value: 'vi' },
    { id: 52, label: 'Cymraeg - Welsh', value: 'cy' },
  ],
};

export default languagesList;
